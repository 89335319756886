import React from 'react';
import LayoutEs from '../components/LayoutEs';
import Header from '../components/Header';
import SEO from '../components/Seo';
import Footer from '../components/Footer';

const PoliticaCookies = () => (
    <LayoutEs>
        <Header />
        <SEO title="Política de privacidad"
            description="Política de privacidad"
            robots="NOINDEX,NOFOLLOW" />
        <header className="masthead legal">
            <div className="text-center">
            <h1 className="display-3">Política de cookies</h1>
                <h4 className="lead mt-5">
                    las galletitas...
                </h4>
            </div>
            <div className="overlay"></div>
        </header>
        <div className="container mt-5 mb-5">
            <h4>Beköme Digital informa que esta página web utiliza cookies propias y de terceros para mejorar nuestros servicios.</h4>
            <p>
                <strong>Definición de las cookies</strong><br />
                ¿Qué son las cookies? Una cookie es un fichero que se descarga en su ordenador al acceder a determinadas páginas web. Las cookies permiten a una 
                página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo 
                de la información que contengan y de la forman que utilice su equipo, pueden utilizarse para reconocer al usuario.
            </p>
            <p>
                <strong>Puede permitir, bloquear o eliminar las cookies</strong><br />
                Las cookies instaladas en su equipo se pueden bloquear o eliminar mediante la configuración de las opciones del navegador instalado en su ordenador.
            </p>
            <p>
                Firefox desde aquí:
                <a href="https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-#w_configurar-las-cookies">https://support.mozilla.org/es/</a>
            </p>
            <p>
                Chrome desde aquí:
                <a href="https://support.google.com/chrome/answer/95647">https://support.google.com</a>
            </p>
            <p>
                Explorer desde aquí:
                <a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/es-es/</a>
            </p>
            <p>
                Safari desde aquí:
                <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac">https://support.apple.com/es-es/</a>
            </p>
            <p>
                Opera desde aquí:
                <a href="https://help.opera.com/en/latest/web-preferences/#cookies">https://help.opera.com/en/</a>
            </p>
        </div>
        <Footer />
    </LayoutEs>
);

export default PoliticaCookies;